<template>
  <HpButton
    :variant="secondary ? 'secondary' : 'primary'"
    @click="apply"
    :compact="compact"
    :disabled="isDisabled"
    :event-data="eventData"
  >
    <slot> {{ text }}</slot>
  </HpButton>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { ProfileStore } from '~/stores/profile'
  import { EngineStore } from '~/stores/engine'
  import { PlanType, WaypointResult } from '~/generated/api-clients-generated'
  import { ApplicationStore } from '~/stores/applications'
  import dayjs from 'dayjs'

  const props = defineProps<{
    medicareId: string
    planYear: number
    planType: PlanType
    compact?: boolean
    secondary?: boolean
    eventData?: any
    from?: string
    disable?: boolean
  }>()

  const session = SessionStore.use()
  const profile = ProfileStore.use()
  const apps = ApplicationStore.use()
  const engine = EngineStore.use()
  const { push } = AppRouter.use()
  const { flag } = FeatureFlags.use()
  const { tagEvent } = SessionReplay.use()
  const { track } = Analytics.use()

  const { isHidden, isInProgress } = ApplicationStatuses.use()

  const isPreshop = computed(() => flag('is-pre-shopping', false).value)
  const showPreshopModal = computed(
    () =>
      props.planType !== PlanType.GAP && isPreshop.value && props.planYear === dayjs().year() + 1
  )

  const { isAuthenticated } = Auth.use()

  const currentApp = computed(() =>
    apps.applications.find(
      (a) => a.medicareId === props.medicareId && a.planYear === props.planYear && !isHidden(a)
    )
  )

  const isDisabled = computed(
    () => props.disable || (currentApp.value && !isInProgress(currentApp.value))
  )

  const text = computed(() => {
    if (props.planType === 4 && !isInProgress(currentApp.value)) return 'Apply'
    if (!currentApp.value) return flag('apply-button-text', 'Apply').value
    if (isInProgress(currentApp.value)) return 'Resume'

    return 'Applied For'
  })

  const { show } = DialogController.use()

  const apply = async () => {
    console.log(props.medicareId, props.planYear)
    console.log(currentApp.value)

    if (props.from == 'best-plans') {
      tagEvent('AppliedFromBestPlans')
    }

    if (showPreshopModal.value) {
      show('preshop-apply-modal')
      track('PreshopAppStarted', {
        medicareId: props.medicareId,
        profileId: profile.id
      })
      if (
        isAuthenticated.value &&
        !profile.savedPlans[props.planYear]?.includes(props.medicareId)
      ) {
        profile.addSavedPlan(props.planYear, props.medicareId)
      }
      return
    }

    try {
      if (!currentApp.value) {
        const engineSnapshot = EngineStore.retrieveSnapshot()

        let quote = engineSnapshot?.quotes?.find((x) => x.medicareId === props.medicareId)
        let details = engineSnapshot?.plans?.find((x) => x.medicareId === props.medicareId)

        if (!quote || !details) {
          console.error(`failed to find quote for ${props.medicareId}`)
          return
        }

        // cloning
        quote = { ...quote }
        details = { ...details }

        if (!_isNil(engineSnapshot)) {
          engineSnapshot.plans = [details]
          engineSnapshot.quotes = [quote]
        }

        session.pendingApplicationData = {
          //@ts-ignore
          profileId: null, // set in PageApplyRouter AFTER sign-up/in
          medicareId: quote.medicareId,
          planName: quote.name,
          planLetter: quote.planLetter,
          planType: quote.type,
          planExternalId: quote.externalId,
          //@ts-ignore
          planYear: details.year,
          policyType: details.policyType,
          //@ts-ignore
          carrierId: details.carrierId,
          //@ts-ignore
          carrierKey: details.carrierKey,
          carrierName: details.carrierName,
          brandName: details.brandName,
          brandId: details.brandId,
          logoKey: details.logoKey,
          monthlyPremium: details.premium,
          sobUrl: details.externalResources?.summaryOfBenefitsUrl,
          eocUrl: details.externalResources?.evidenceOfCoverageUrl,
          snpType: details.snpType,
          flow: details.appFlow,
          destination: details.appDestination,
          naic: details.naic,
          engineData: engineSnapshot as { [key: string]: any }
        }
      } else {
        session.currentApplicationId = currentApp!.value.id!
      }

      console.log(session.pendingApplicationData)

      const autopilot = WaypointAutoPilot.use(WaypointResult.GoTo_Apply)
      await autopilot.engage()
    } catch (err) {
      console.error(err)
    }
  }
</script>

<style scoped></style>
